
import { StyleSheet, Font } from '@react-pdf/renderer';

import Inter from './WorksheetFonts/Inter.ttf';
import OpenDyslexic from './WorksheetFonts/OpenDyslexic3-Regular.ttf';
import NotoSans from './WorksheetFonts/NotoSans-VariableFont_wdth,wght.ttf';
import NotoSansTC from './WorksheetFonts/NotoSansTC-Regular.ttf';
import NotoSansKR from './WorksheetFonts/NotoSansKR-Regular.ttf';
import NotoSansHebrew from './WorksheetFonts/NotoSansHebrew-Regular.ttf';
import NotoSansThai from './WorksheetFonts/NotoSansThai-Regular.ttf';
import NotoSansJP from './WorksheetFonts/NotoSansJP-Regular.ttf';
import NotoSansArabic from './WorksheetFonts/NotoSansArabic-Regular.ttf'

import HalfTermSchoolsOut from './WorksheetFonts/HalfTermSchoolsOut.ttf';
import BubblegumSans from './WorksheetFonts/BubblegumSans-Regular.ttf';


Font.register({
    family: 'Inter',
    src: Inter,
});

Font.register({
    family: 'OpenDyslexic',
    src: OpenDyslexic,
});

Font.register({
    family: 'NotoSans',
    src: NotoSans,
});

Font.register({
    family: 'NotoSansTC',
    src: NotoSansTC,
});

Font.register({
    family: 'NotoSansKR',
    src: NotoSansKR,
});

Font.register({
    family: 'NotoSansJP',
    src: NotoSansJP,
});

Font.register({
    family: 'NotoSansHebrew',
    src: NotoSansHebrew,
});

Font.register({
    family: 'NotoSansThai',
    src: NotoSansThai,
});

Font.register({
    family: 'NotoSansArabic',
    src: NotoSansArabic,
});

Font.register({
    family: 'HalfTermSchoolsOut',
    src: HalfTermSchoolsOut,
});

Font.register({
    family: 'BubblegumSans',
    src: BubblegumSans,
});


const applyStyles = ({ styles, styleNames = [], isPdf }) => {
    // Initialize the final style array for PDF or object for HTML
    const finalStyle = isPdf ? [] : {};

    // Apply base styles
    styleNames.forEach(styleName => {
        const baseStyle = styles[styleName];
        if (isPdf) {
            finalStyle.push(baseStyle);
        } else {
            Object.assign(finalStyle, baseStyle);
        }
    });

    return finalStyle;
};


// Create styles
const createStylesPDF = ({ fontSize, lineSpacing, color = "black" }) =>
    StyleSheet.create({
        page: {
            fontFamily: 'NotoSans',
            letterSpacing: '1.2px',
            justifyContent: 'space-between',
            height: '100%',
        },
        dyslexicMode: {
            justifyContent: 'space-between',
            fontFamily: 'OpenDyslexic',
            textAlign: 'left',
            letterSpacing: 0.35,
            lineHeight: 2,
            fontWeight: 600,
        },
        chineseText: {
            fontFamily: 'NotoSansTC',
        },
        koreanText: {
            fontFamily: 'NotoSansKR',
        },
        japaneseText: {
            fontFamily: 'NotoSansJP',
        },
        arabicText: {
            fontFamily: 'NotoSansArabic'
        },
        hebrewText: {
            fontFamily: 'NotoSansHebrew'
        },
        thaiText: {
            fontFamily: 'NotoSansThai'
        },
        inlineText: {
            display: 'inline'
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 10,
        },
        headerBanner: {
            width: 1500
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: "relative",
            left: 0,
            bottom: 0,
        },
        footerBanner: {
            width: 1500,
        },
        logo: {
            width: '100px',
            height: 'auto',
            marginLeft: 30,
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: 100,
        },
        infoText: {
            marginBottom: 5,
        },
        content: {
            padding: 20,
            flexGrow: 1,
        },
        h1: {
            fontFamily: 'BubblegumSans',
            textAlign: 'center',
            fontSize: 36,
            marginBottom: 10,
            color: "#6a49db"
        },
        h2: {
            fontFamily: 'BubblegumSans',
            textAlign: 'center',
            fontSize: 24,
            marginBottom: 10,
            color: "#6a49db"
        },
        instructions: {
            textAlign: 'center',
            fontSize: `${fontSize}`,
            lineHeight: `${lineSpacing}`,
            marginVertical: 20,
        },
        textBox: {
            padding: 10,
            fontSize: `${fontSize}`,
            marginBottom: 20,
            border: '1px dotted #593cb6',
            borderRadius: 5,
            justifyContent: 'center',
        },
        writingLine: {
            padding: 7,
            fontSize: `${fontSize}`,
            borderBottom: '1px dashed #555555',
            display: 'block',
            paddingBottom: '5px', /* This will increase the total height */
            marginBottom: '10px' /* Ensures space below */
        },
        answersHeader: {
            padding: 10,
            fontSize: `${fontSize}`,
            marginBottom: 20,
            border: '1px dotted #593cb6',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: 10
        },
        glossaryItems: {
            padding: 10,
            fontSize: `${fontSize}`,
            marginBottom: 20,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: 10
        },
        answerText: {
            margin: 5,
            fontSize: `${fontSize - 2}`,
        },
        questionContainer: {
            padding: 10,
        },
        questionText: {
            fontSize: `${fontSize}`,
            lineHeight: `${(lineSpacing)}`,
            color: `${color}`,
        },
        answerOptionsContainer: {
            paddingTop: 5,
            paddingLeft: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        answerOption: {
            paddingLeft: 5,
            fontSize: `${fontSize}`,
            lineHeight: `${(lineSpacing)}`,
            fontWeight: 600,
        },
        bold: {
            fontWeight: 'bold',
            display: 'inline'
        },
        italics: {
            fontStyle: 'italic',
            display: 'inline',
        },
    });



// Create styles
const createStylesHTML = ({ fontSize, lineSpacing, color = "black" }) => ({


    page: {
        fontFamily: 'NotoSans, sans-serif',
        letterSpacing: '1.2px',
    },
    dyslexicMode: {
        justifyContent: 'space-between',
        fontFamily: 'OpenDyslexic, sans-serif',
        textAlign: 'left',
        letterSpacing: '0.35px',
        lineHeight: '2',
        fontWeight: 600,
    },
    inlineText: {
        display: 'inline',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '30px'
    },
    headerBanner: {
        width: '100%',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        left: 0,
        bottom: 0,
        paddingTop: '30px'
    },
    footerBanner: {
        width: '100%',
    },
    logo: {
        width: '100px',
        height: 'auto',
        marginLeft: '30px',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginRight: '100px',
    },
    infoText: {
        marginBottom: '5px',
    },
    content: {
        flexGrow: 1,
    },
    h1: {
        fontFamily: 'BubblegumSans',
        textAlign: 'center',
        fontSize: '36px',
        color: '#6a49db',
    },
    h2: {
        fontFamily: 'BubblegumSans',
        textAlign: 'center',
        fontSize: '24px',
        color: '#6a49db',
    },
    instructions: {
        textAlign: 'center',
        fontSize: `${fontSize}px`,
        lineHeight: `${lineSpacing}`,
        marginTop: '20px',
        marginBottom: '20px',
    },
    textBox: {
        padding: '10px',
        fontSize: `${fontSize}px`,
        marginBottom: '20px',
        border: '1px dotted #593cb6',
        borderRadius: '5px',
        justifyContent: 'center',
    },
    writingLine: {
        padding: 5,
        fontSize: `${fontSize}`,
        borderBottom: '1px dashed #555555',
    },
    answersHeader: {
        padding: '10px',
        fontSize: `${fontSize}px`,
        marginBottom: '20px',
        border: '1px dotted #593cb6',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        gap: '10px',
        marginLeft: '30px',
        marginRight: '30px',
    },
    glossaryItems: {
        padding: '10px',
        fontSize: `${fontSize}px`,
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        gap: '20px',
        marginLeft: '30px',
        marginRight: '30px',
    },
    answerText: {
        margin: '5px',
        fontSize: `${fontSize - 2}px`,
    },
    questionContainer: {
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    questionText: {
        fontSize: `${fontSize}px`,
        lineHeight: `${lineSpacing}`,
        whiteSpace: 'pre-wrap',
        color: `${color}`,
    },
    answerOptionsContainer: {
        paddingTop: '5px',
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    answerOption: {
        fontSize: `${fontSize}px`,
        lineHeight: `${lineSpacing}`,
        paddingLeft: '5px',
    },
    chineseText: {
        fontFamily: 'NotoSansTC, sans-serif',
    },
    koreanText: {
        fontFamily: 'NotoSansKR, sans-serif',
    },
    japaneseText: {
        fontFamily: 'NotoSansJP, sans-serif',
    },
    arabicText: {
        fontFamily: 'NotoSansArabic, sans-serif'
    },
    hebrewText: {
        fontFamily: 'NotoSansHebrew, sans-serif'
    },
    thaiText: {
        fontFamily: 'NotoSansThai, sans-serif'
    },
}
);


export { createStylesHTML, createStylesPDF, applyStyles }
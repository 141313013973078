import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { PageWrap, ImageWrap, ViewWrap, DocumentWrap, TextWrap, EmbeddedTextWrap } from './WorksheetWrappers';

import WorksheetHeaderBanner1 from './assets/WorksheetHeaderBanner2.png';
import WorksheetFooterBanner1 from './assets/WorksheetFooterBanner.png';
import WorksheetHeaderBanner2 from './assets/WorksheetHeaderBanner.png';
import WorksheetFooterBanner2 from './assets/WorksheetFooterBanner2.png';

import { singleWorksheetDataPropType } from '../pages/CreateWorksheet/CreateWorksheetConstants';
import AnswerKey from './AnswerKey';
import { splitText } from './LanguageAccessability';

import './WorksheetTemplate.css';


const headers = [WorksheetHeaderBanner1, WorksheetHeaderBanner2];

const footers = [WorksheetFooterBanner1, WorksheetFooterBanner2];

const getFullHeight = (element) => {
    const rect = element.getBoundingClientRect();
    const style = window.getComputedStyle(element);
    const padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
    const border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
    const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    return rect.height + padding + border + margin;
};

// Define the default instructions based on worksheet type
const getWorksheetInstructions = (type) => {
    switch (type) {
        case "multiple choice":
            return "Choose the correct answer to each question to test your understanding.";
        case "fill in the blank":
            return "Fill in the missing words to complete each sentence.";
        case "reading comprehension":
            return "Read the passage carefully, then answer the following questions.";
        case "written response":
            return "Write your answer to each question in full sentences.";
        case "quiz worksheet":
            return "Answer a variety of question types to demonstrate your knowledge.";
        default:
            return "Placeholder instructions"; // Fallback if no type is matched
    }
};

// Create Document Component
function WorksheetTemplate({ worksheetData, worksheetIndex, children, config, isPdf }) {

    // For use in paginated worksheet preview
    const parentRef = useRef(null);
    const childrenRefs = useRef([]);
    const [insertedAbove, setInsertedAbove] = useState({});
    const [prevChildren, setPrevChildren] = useState({});
    const titleRef = useRef(null);
    const instructionsRef = useRef(null);
    const [titleHeight, setTitleHeight] = useState(0);

    // Title/instruction height
    useEffect(() => {

        if (isPdf || worksheetIndex === -1) return;

        if (titleRef.current && instructionsRef.current) {
            setTitleHeight(getFullHeight(titleRef.current) + getFullHeight(instructionsRef.current));
        }
    }, [isPdf, worksheetIndex]);

    // Pagination
    useEffect(() => {

        if (isPdf || worksheetIndex === -1 || !titleHeight) return;

        // console.log("use effect running...");

        const checkPositionRelativeToParent = () => {
            // console.log("number of child refs:", childrenRefs.current.length); // Access childrenRefs.current

            const relativeTriggerHeight = 842; // A4 height for triggering
            let topOfPage = -titleHeight; // start after the title/instructions
            let currentHeight = 0;
            let lastHeight = 0;
            let currChild = null;

            // Reset all headers, footers
            if (prevChildren !== children) {
                setInsertedAbove({})
            }

            childrenRefs.current.forEach((child, index) => {
                if (child) {
                    currChild = child;
                    lastHeight = currentHeight;
                    currentHeight += getFullHeight(child);
                    const spaceRemaining = relativeTriggerHeight - (lastHeight - topOfPage);

                    // console.log("top of page", topOfPage)
                    // console.log("last elements height", lastHeight - topOfPage)
                    // console.log("current element bottom height:", currentHeight - topOfPage)
                    // console.log("current element space to next page", relativeTriggerHeight - (currentHeight - topOfPage))


                    // Check if the child has reached a certain height relative to the parent's height
                    if ((currentHeight - topOfPage > relativeTriggerHeight) && !insertedAbove[index]) {                        // Insert element above this child if it hasn't been inserted yet
                        setInsertedAbove((prev) => ({ ...prev, [index]: spaceRemaining }));
                    }

                    topOfPage += (currentHeight - topOfPage > relativeTriggerHeight) ? relativeTriggerHeight : 0
                }
            });

            // Calculate the space remaining to fill the last page
            const remainingSpace = relativeTriggerHeight - (currentHeight - topOfPage);
            // console.log(`Remaining spcace after last child: ${remainingSpace}px`);

            // Insert an element to fill the gap after the last child
            if (remainingSpace > 0) {

                const existingFillerElements = document.querySelectorAll(".end-filler-element");
                existingFillerElements.forEach((el) => el.remove());

                const fillerElement = document.createElement("div");
                fillerElement.style.height = `${remainingSpace}px`;
                fillerElement.classList.add("end-filler-element"); // Add a class to track the filler element

                // Append the filler element after the last child
                currChild.parentNode.appendChild(fillerElement);
            }
        };

        checkPositionRelativeToParent(); // Perform the check when the parent's height is available
        setPrevChildren(children)
    }, [titleHeight, prevChildren, insertedAbove, childrenRefs, children, isPdf, worksheetIndex]);

    const worksheetTitle = worksheetData.worksheet_title ? splitText(worksheetData.worksheet_title) : "Fill In The Blank!";

    const header = (<ViewWrap isPdf={isPdf} styleProps={config} styleName="header" elementKey="header">
        <ImageWrap isPdf={isPdf} styleProps={config} styleName="headerBanner" src={headers[worksheetData.style ?? 0]} />
    </ViewWrap>)

    const footer = (<ViewWrap isPdf={isPdf} styleProps={config} styleName="footer" elementKey="footer">
        <ImageWrap isPdf={isPdf} styleProps={config} styleName="footerBanner" src={footers[worksheetData.style ?? 0]} />
    </ViewWrap>)

    return (
        <DocumentWrap ref={parentRef} isPdf={isPdf}>
            <PageWrap
                isPdf={isPdf}
                size="A4"
                // style={worksheetData.accommodations.IEP.accomodations.includes('Dyslexia') ? styles.dyslexicMode : styles.page}
                styleProps={config}
                key={`${worksheetIndex}-page`}
            >
                {header}
                <ViewWrap isPdf={isPdf} styleProps={config} styleName="content" elementKey="content">
                    <>
                        {(worksheetIndex === -1) && <TextWrap
                            isPdf={isPdf}
                            styleProps={config}
                            styleNames={['h1']}
                        > </TextWrap>}
                        <EmbeddedTextWrap
                            isPdf={isPdf}
                            styleProps={config}
                            styleName='h1'
                            elementKey="worksheetTitle"
                        >
                            {worksheetTitle.map((part, index) => (
                                <TextWrap
                                    key={`titleSegment-${index + 1}`}
                                    isPdf={isPdf}
                                    styleProps={config}
                                    styleNames={['h1', 'inlineText', part.script]}
                                    elementKey={`titleSegment-${index + 1}`}
                                    ref={titleRef}
                                >
                                    {part.text}
                                </TextWrap>
                            ))}

                        </EmbeddedTextWrap>

                        <TextWrap
                            isPdf={isPdf}
                            styleProps={config}
                            styleNames={['instructions']}
                            elementKey="instructionsText"
                            ref={instructionsRef}
                        >
                            {worksheetData.instructions ?? getWorksheetInstructions(worksheetData.worksheet_type)}
                        </TextWrap>

                    </>

                    {isPdf && children}
                    {!isPdf && worksheetIndex === -1 && children}
                    {!isPdf && (worksheetIndex !== -1) && React.Children.map(children, (child, index) => (
                        <>
                            {insertedAbove[index] && (
                                <>
                                    {insertedAbove[index] > 0 && (<div style={{ height: `${insertedAbove[index]}px` }} className="filler-element"/>)}
                                    {footer}
                                    {header}
                                    {/* {console.log("hi", insertedAbove[index])} */}
                                </>
                            )}
                            <div ref={(el) => { childrenRefs.current[index] = el; }}>
                                {child}
                            </div>
                        </>
                    ))}

                    {(worksheetIndex === -1) && <TextWrap
                        isPdf={isPdf}
                        styleProps={config}
                        styleNames={['h1']}
                    > <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></TextWrap>}

                </ViewWrap>
                {footer}
            </PageWrap>
            {config.answerKey && <AnswerKey worksheetData={worksheetData} worksheetIndex={worksheetIndex} isPdf={isPdf} />}
        </DocumentWrap>
    );
}

WorksheetTemplate.propTypes = {
    worksheetData: singleWorksheetDataPropType,
    worksheetIndex: PropTypes.number,
    children: PropTypes.node.isRequired,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
        linesBetweenQuestions: PropTypes.number,
        answerKey: PropTypes.bool
    })
}

export default WorksheetTemplate;

import React, { useState } from 'react';
import { useFirestore } from 'reactfire';
import { collection, addDoc } from 'firebase/firestore';

import "./Feedback.css";
import "../Help.css";

function Feedback() {
    const [feedback, setFeedback] = useState("");
    const firestore = useFirestore();

    async function submitFeedback() {
        try {
            const feedbackRef = collection(firestore, 'feedback');

            await addDoc(feedbackRef, {
                userFeedback: feedback,
                createdAt: new Date()
            });

            console.log('Feedback sent successfully.');
        } catch (error) {
            console.error('Error submitting feedback: ', error.message);
        }
    }

    const handleInputChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Feedback submitted:", feedback);
        submitFeedback();
        setFeedback("");
    };

    return (
        <form className="feedback-form" onSubmit={handleSubmit}>
            <label 
                className="help-section-title"
                htmlFor="feedback"
            >
                Your Feedback:
            </label>
            <p className="help-section-subtitle">
                We value your input! Let us know how we can improve your experience.
            </p>
            <textarea
                id="feedback"
                className="feedback-textarea"
                value={feedback}
                onChange={handleInputChange}
                placeholder="Share your thoughts, suggestions, or any issues you encountered..."
                rows="4"
            />
            <button type="submit">Send Feedback</button>
        </form>
    );
}

export default Feedback;

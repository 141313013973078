import React from "react";
import PropTypes from 'prop-types';
import "./PopupConfirm.css";

import exitIcon from '../../assets/shared/exitIcon-black.png';

function PopupConfirm({ displayText, confirmText, exitText, handleConfirm, handleExit }) {
    return (
      <div className="popupConfirm-overlay">
        <div className="popupConfirm">
          
          <div className="popupConfirm-header">
            <button type="button" onClick={handleExit} className="popupConfirm-close-button">
              <img className="popupConfirm-close-button-img" src={exitIcon} alt="exit"/>
            </button>
          </div>
          <div className="popupConfirm-body">
            <p className="popupConfirm-description">{displayText}</p>
            
            <div className="popupConfirm-buttonOptions">
                <button type="button" onClick={handleConfirm} className="popupConfirm-button">
                {confirmText}
                </button>

                <button type="button" onClick={handleExit} className="popupConfirm-button">
                {exitText}
                </button>
            </div>

            </div>
          </div>
        </div>
    );
}

PopupConfirm.propTypes = {
    displayText: PropTypes.string, 
    confirmText: PropTypes.string, 
    exitText: PropTypes.string, 
    handleConfirm: PropTypes.func, 
    handleExit: PropTypes.func
};

export default PopupConfirm;
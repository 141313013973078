import React, { useState } from "react";
import PropTypes from "prop-types";

import standardsIcon from '../../../../assets/CreateWorksheet/standardsIcon.svg';
import expandIcon from '../../../../assets/CreateWorksheet/expandIcon.svg';

import { generalInfoPropType } from "../../CreateWorksheetConstants";


function AdditionalSpecs({ generalInfo, setGeneralInfo}) {
    const [expanded, setExpanded] = useState(false);

    const handleAdditionalSpecChange = (event) => {
        setGeneralInfo({
            ...generalInfo, // Copy the old fields
            additional_prompt: event.target.value // But override this one
          });
	};
    
    return (
        <div className="createWorksheet-configure-info-section">
            <div
                className="createWorksheet-configure-info-header"
            >
                <div 
                    style={{display: "flex", gap: "5px", minWidth: "280px", alignItems: "flex-start"}}    
                >
                <img 
                    className="createWorksheet-configure-field-icon"
                    src={standardsIcon} alt="Additional Specifications for Your Worksheet"/>
                    Additional Specifications for Your Worksheet:
                <p style={{color: "var(--mid-light-grey)", alignSelf: "flex-start", fontSize: "14px", margin: "0", paddingTop: "3px"}}>
                    (optional)
                </p>
                {/* Borrowing align to standard css properties here */}
                <button type="button" className="alignToStandards-arrow-toggle-container" onClick={() => setExpanded(!expanded)}>
                    <img className={`alignToStandards-arrow ${expanded ? 'up' : 'down'}`} src={expandIcon} alt="Toggle Expand"/>
                </button>
                </div>
            </div>
                
            { expanded && 
                <input 
                    type="text"
                    id="createWorksheet-configure-info-additionalPrompt" 
                    name="createWorksheet-configure-info-additionalPrompt"
                    className="createWorksheet-configure-info-textInput"
                    placeholder="Eg. include more questions on photosynthesis, make all questions Star Wars themed"
                    autoComplete="off"
                    value={generalInfo.additional_prompt}
                    onChange={handleAdditionalSpecChange}
                />
            }
        </div>
  );
}

AdditionalSpecs.propTypes = {
    generalInfo:generalInfoPropType,
    setGeneralInfo: PropTypes.func,
};

export default AdditionalSpecs;
